/* .App {
  text-align: center;
} */
body { 
  margin:0;
  background:#f4f8fa
}
h2 {
  margin-top:40px;
  text-align:center;
}
.container {
  display:none;
  width:800px;
  margin:0 auto;
  margin-top:30px
}
.skip-to-form { 
  left:-9999px;
  position:absolute
}
@media only screen and (max-width:800px){
  .container { 
    width:100% 
  }
}
.container.error,.container.loaded {
  display:block
} 
.container.loaded { 
  background:#fff;
  box-shadow:0 4px 8px 0 rgba(53,105,128,.3),0 6px 20px 0 rgba(165,200,213,.41)
}
#form-target {
  padding:40px
}
.hs-form_free.hs-form__thankyou-message {
  font-size:38px;
  text-align:center
}
.hs-form_free.hs-form__thankyou-message .hs-form__virality__link {
  font-size:18px; 
  margin-top:240px;
  line-height:60px
}
.hs-form_free.hs-form__thankyou-message .hs-form__virality__link__sproket {
  width:60px;
  height:60px
}
.hs-form_free.hs-form__thankyou-message:before {
  display:block;
  content:url(https://js.hsformsqa.net/success-green.svg);
  width:130px;
  height:201px;
  padding-top:28px;margin:0 auto
}
.hs-form_theme-round .hs-form__field__input {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  background-clip: padding-box;
  padding: 10px 15px;
  width: 100%;
  height: 40px;
}

hs-form input, .hs-form select, .hs-form textarea {
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
}
.hs-form * {
  box-sizing: border-box;
}
hs-form__field, .hs-form__field__label {
  font-size: 14px;
  width: 130px;
  color: #33475b;
  display: block;
  float: none;
  width: auto;
  font-weight: 500;
  line-height: 20px;
  padding-top: 0;
  margin-bottom: 4px;
}
.hs-form-2061156a-9a56-436f-9fdb-39a0fe96f3e0 {
  font-family: arial, helvetica, sans-serif;
}
.hs-form__field-row__column {
font-family: arial, helvetica, sans-serif;
box-sizing: border-box;
align-self: flex-start;
flex: 1 1 100%;
padding: 0 10px;
width: 100%;
}
.hs-form__field-row {
font-family: arial, helvetica, sans-serif;
box-sizing: border-box;
margin-left: -10px;
margin-right: -10px;
display: flex;
flex-grow: 1;
align-items: flex-start;
justify-content: flex-start;
flex-direction: row;
}
.hs-form__field, .hs-form__field-email .hs-email {
  font-family: arial, helvetica, sans-serif;
  font-size: 14px;
  color: #33475b;
  display: block;
  float: none;
  width: auto;
  font-weight: 500;
  line-height: 20px;
  padding-top: 0;
  margin-bottom: 4px;
  box-sizing: border-box;
}
.hs-form__field, .hs-form__field-user_gender, .hs-user_gender {
  font-family: arial, helvetica, sans-serif;
  font-size: 14px;
  color: #33475b;
  display: block;
  float: none;
  width: auto;
  font-weight: 500;
  line-height: 20px;
  padding-top: 0;
  margin-bottom: 4px;
  box-sizing: border-box;
}
.hs-form-2061156a-9a56-436f-9fdb-39a0fe96f3e0, .hs-form, .hs-form_theme-round{
  -webkit-locale: "en";
  font-family: arial, helvetica, sans-serif;
  width: 100%;
}
.hs-form__row {
  font-family: arial, helvetica, sans-serif;
  box-sizing: border-box;
  margin-bottom: 18px;
}
.hs-form__actions {
  font-family: arial, helvetica, sans-serif;
  box-sizing: border-box;
  text-align: left;
}
.hs-form__actions__submit{
  margin: 0;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  position: relative;
  text-align: center;
  border: 1px solid #ff7a59;
  padding: 12px 24px;
  box-sizing: border-box;
  border-radius: 15px;
  background: #ff7a59;
  border-color: #ff7a59;
  color: #ffffff;
  font-family: arial, helvetica, sans-serif;
  line-height: 12px;
  font-size: 12px;
}
.error-message {
  color: red;
  position: relative;
  text-align: center;
  display: inline-block;
  font-weight: 700;
}
#form-target{
padding: 40px;
}

.svgLoader {
  animation: spin 2s linear infinite;
  margin-top: -12rem;
}
.divLoader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
}
.background-blur{
  filter: blur(1px);
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}